<template>
  <div>
    <div class="banner-limit policy-detail-content" style="min-height: 24px;margin-top: 30px">
      <img src="~@/assets/images/wz_icon.png" alt="" style="" />
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        style="font-size: 18px; display: inline-block; margin-left: 8px"
      >
        <el-breadcrumb-item :to="{ path: '/policyLibrary' }"
          >政策检索</el-breadcrumb-item
        >
         <el-breadcrumb-item :to="{ path: `/notice/detail/${policyId}` }"
          >政策详情</el-breadcrumb-item
        >
        <el-breadcrumb-item>政策解读</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="banner-limit">
      <div class="dialogContent">
        <el-scrollbar style="width: 100%">
          <p class="jdTitle">{{this.$route.query.title}}解读 &nbsp;|&nbsp;{{ sysPolicyExplain.name }}</p>
          <div
            style="
              display: flex;
              justify-content: center;
              color: #aaa;
              margin-bottom: 20px;
            "
          >
            <p style="margin-right: 20px">
              所属区划：{{ sysPolicyExplain.areaname }}
            </p>
            <p>颁布部门：{{ deptName }}</p>
          </div>
          <div class="detailContent" v-html="detailContent" ></div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "policyExplainPage",
  components: {},
  data() {
    return {
      detailContent: "",
      policyExplainDataIndex: "",
      deptName: "",
      optionsDept: [],
      optionsDeptArr: [],
      sysPolicyExplain: {
        sort: 1,
        contentReceive: [
          {
            title: "图片",
            type: "1",
            name: "1",
            status: "0",
            content: "",
          },
          {
            title: "图文",
            type: "2",
            name: "2",
            status: "0",
            content: "",
          },
          {
            title: "文字",
            type: "3",
            name: "3",
            status: "0",
            content: "",
          },
        ],
      },
    };
  },
  created() {
    let tt = sessionStorage.getItem("policyExplainData");
    this.policyExplainDataIndex = sessionStorage.getItem(
      "policyExplainDataIndex"
    );
    this.sysPolicyExplain = JSON.parse(tt);
    this.policyId = this.$route.query.policyId;
    this.detailContent =
      this.sysPolicyExplain.contentReceive[this.policyExplainDataIndex].content;
    this.getTree();
  },
  mounted() {},
  methods: {
    // 遍历树形结构
    readNodes(nodes = [], arr = []) {
      for (let item of nodes) {
        arr.push({
          id: item.id,
          label: item.label,
          value: item.value,
        });
        if (item.children && item.children.length)
          this.readNodes(item.children, arr);
      }
      return arr;
    },
    // 获取部门
    getTree() {
      let that = this;
      let url = "/dev-api/web/dept/treeCascader";
      that.$httpApi.get(url, {}).then((res) => {
        that.optionsDept = res.data;
        that.optionsDeptArr = that.readNodes(res.data);
        that.optionsDeptArr.forEach((item) => {
          if (
            item.id ==
            that.sysPolicyExplain.deptid[
              that.sysPolicyExplain.deptid.length - 1
            ]
          ) {
            that.deptName = item.label;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input.is-disabled .el-input__inner {
  border-color: #fff !important;
  background-color: #fff !important;
  color: #aaa;
}
::v-deep .el-icon-arrow-down:before {
  content: "";
}
::v-deep .el-form-item__label {
  color: #aaa;
}
.jdTitle {
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin: 20px auto;
}
.detailContent {
  width: 100%;
  min-height: 500px;
  margin-bottom: 10px;
  text-align: center;
  ::v-deep p {
    width: 100%;
    text-align: left;
    text-indent: 2rem !important;
    line-height: 30px !important;
  }
  ::v-deep img {
    width: 100% !important;
    // vertical-align: middle !important;
  }
}
</style>
